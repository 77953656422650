<style scoped lang="less">
  .page-base {
    background-color: #F7F9FE;
    padding: 20px;
    position: relative;
    box-sizing: border-box;
    &.has-label {
      padding-top: 40px;
    }
  }
  .labels {
    position: absolute;
    top: 13px;
    left: 20px;
    .label {
      border-radius: 8px 8px 0px 0px;
      background: #B3B3B3;
      box-sizing: border-box;
      display: inline-block;
      padding: 3px 24px;
      text-align: center;
      color: #FFF;
      cursor: pointer;
      transition: all .3s;
      &:hover:not(.active) {
        color: #2E6BE5;
      }
      &.active {
        cursor: default;
        background: #2E6BE5;
      }
      & + .label {
        margin-left: 1px;
      }
    }
  }
  .header {
    border-bottom: 1px solid #E9E9E9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
  .tabs {
    display: flex;
    div {
      padding: 0 10px;
      line-height: 40px;
      cursor: pointer;
      box-sizing: border-box;
      transition: all .3s;
      font-size: 15px;
      color: #666666;
      &::after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: #2E6BE5;
        opacity: 0;
        position: absolute;
        transition: opacity .3s;
        left: 0;
        bottom: 0;
      }
      &:hover {
        color: #2E6BE5;
      }
      &.active {
        color: #2E6BE5;
        position: relative;
        &::after {
          opacity: 1;
        }
      }
      & + div {
        margin-left: 20px;
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 53px;
    & > * + * {
      margin-left: 10px;
    }
  }
  .container {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .content {
    position: relative;
    height: 0;
    flex: 1;
    overflow: hidden;
    // margin: 20px 20px 0 20px;
    box-sizing: border-box;
  }
  .title {
    border-bottom: 1px dashed #E9E9E9;
    display: flex;
    align-items: center;
    .text {
      font-weight: 800;
      display: flex;
      align-items: center;
      height: 53px;
      &:before {
        background-color: #005AFF;
        content: '';
        width: 3px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
</style>

<style lang="less">
  .fm-modal-body {
    .page-base {
      padding: 0;
    }
  }
</style>

<template>
  <div class="page-base">
    <div class="container">
      <div class="labels" v-if="labels && labels.length">
        <div class="label" @click="labelSwitch(item)" :class="{active: active.label === item.value}" v-for="(item, i) in labels" :key="item.value + i">{{item.label}}</div>
      </div>
      <template v-if="tabs && tabs.length">
          <div class="title" v-if="filters && filters.length || title">
            <div class="text" v-if="title">{{title}}</div>
            <table-search style="flex: 1;" v-if="!$slots.search && filters && filters.length" @change="(data) => $emit('search', data)" :filters="filters" />
            <slot v-else name="search" />
          </div>
          <div class="header" v-if="(tabs && tabs.length) || (pageActions && pageActions.length)">
            <div class="tabs" v-if="tabs && tabs.length">
              <div :class="{active: active.tab === item.value}" @click="tabSwitch(item)" v-for="item in tabs" :key="item.value">{{item.label}}</div>
            </div>
            <div class="actions" v-if="pageActions && pageActions.length">
              <fm-btn v-for="item in pageActions" @click="$emit('page-action', item.value)" :key="item.value">{{item.label}}</fm-btn>
            </div>
          </div>
      </template>
      <template v-else>
        <div class="title" v-if="filters && filters.length || title">
          <div class="text" v-if="title">{{title}}</div>
          <table-search style="flex: 1;" v-if="!$slots.search && filters && filters.length" @change="(data) => $emit('search', data)" :filters="filters" />
          <slot v-else name="search" />
          <div style="margin-right: 10px;" class="actions" v-if="pageActions && pageActions.length">
            <fm-btn v-for="item in pageActions" @click="$emit('page-action', item.value)" :key="item.value">{{item.label}}</fm-btn>
          </div>
        </div>
      </template>
      <div class="content">
        <fm-table-new
          v-if="!$slots.default"
          :columns="tableColumns"
          :auto-height="true"
          v-loadingx="loading"
          :simple-filter="simpleFilter"
          border="row"
          ref="table"
          :data-list="dataList"
          :stripe="false"
          emptyPlaceholder="-">
          <div slot="empty" style="margin: 30px 0;">
            <img src="/static/images/null.png" style="width: 40px;"/>
            <div>暂无数据</div>
          </div>
          <table-actions slot="actions" @table-action="(data) => $emit('table-action', data)" slot-scope="{ row, index }" :data="tableActions" :index="index" :row="row"></table-actions>
        </fm-table-new>
        <slot v-else></slot>
      </div>
      <div v-if="pageNum !== undefined" style="display: flex;justify-content: flex-end; padding: 10px 20px;box-sizing: border-box;">
        <fm-page 
          :current="pageNum"
          :total="total"
          :page-size="pageSize"
          @change="(page) => $emit('page-change', page)"
          @page-size-change="(size) => $emit('page-size-change', size)"
          show-total
          show-sizer
          show-elevator
        />
      </div>
    </div>
    <slot name="modal"></slot>
  </div>
</template>

<script>
import TableSearch from '@/components/search/table'
import TableActions from '@/components/base/TableActions'

export default {
  components: { TableSearch, TableActions },
  props: {
    title: { type: String, default: '' },
    tableColumns: { type: Array, default: () => ([]) },
    dataList: { type: Array, default: () => ([]) },
    tableActions: { type: Array, default: () => ([]) },
    pageActions: { type: Array, default: () => ([]) },
    labels: { type: Array, default: () => ([]) },
    label: { type: [String, Number], default: null },
    filters: { type: Array, default: () => ([]) },
    tabs: { type: Array, default: () => ([]) },
    tab: { type: [String, Number], default: null },
    pageNum: { type: Number, default: undefined },
    simpleFilter: { type: Boolean, default: false },
    total: { type: Number, default: 0 },
    pageSize: { type: Number, default: 10 },
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      active: {
        label: this.label,
        tab: this.tab
      }
    }
  },
  watch: {
    label (label) {
      this.active.label = label
    },
    tab (tab) {
      this.active.tab = tab
    },
  },
  methods: {
    labelSwitch (item) {
      this.active.label = item.value
      this.$emit('label-switch', item.value)
    },
    tabSwitch (item) {
      this.active.tab = item.value
      this.$emit('tab-switch', item.value)
    }
  }
}
</script>
