<style scoped lang="less"></style>

<template>
  <PageBase
    title="药品种类"
    @page-change="onPageChange"
    :dataList="datas"
    :table-columns="tableColumns"
    :table-actions="[]"
    simpleFilter
    :loading="loading.load"
  >
  </PageBase>
</template>

<script>
import { medicineTypeRequest } from '@/api'
import PageBase from '@/components/page/base'

export default {
  components: { PageBase },
  data () {
    return {
      loading: {
        load: false
      },
      datas: []
    }
  },
  computed: {
    tableColumns () {
      return [{ title: '名称', field: 'name'}]
    },
    dataList () {
      return this.datas
    }
  },
  methods: {
    async loadData () {
      this.loading.load = true
      const res = await medicineTypeRequest.get({})
      this.datas = res
      this.loading.load = false
    }
  },
  mounted () {
    this.loadData() 
  }
}
</script>
