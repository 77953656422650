<style scoped lang="less">
  .table-search {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
  }
  .toggle-btn {
    cursor: pointer;
  }
</style>

<style lang="less">
  .table-search {
    .fm-down-select-item {
      padding: 5px 10px;
    }
  }
</style>

<template>
  <div class="table-search">
    <fm-form inline class="search-form">
      <fm-form-item style="margin-bottom: 0;padding-top: 10px; padding-bottom: 10px;" v-for="item in inputs" :key="item.field" :label="item.label">
        <fm-input-new @change="onChange" clearable v-if="!item.type || item.type === 'text'" :placeholder="item.label + '搜索'" v-model="search[item.field]" />
        <fm-select @change="onChange" clearable filterable v-else-if="item.type === 'select'" :multiple="item.multiple" :placeholder="item.label + '搜索'" v-model="search[item.field]">
          <fm-option v-for="(option, i) in item.filters" :key="option.value + i" :label="option.label" :value="option.value"></fm-option>
        </fm-select>
      </fm-form-item>
    </fm-form>
    <fm-down-select position="bottom-end" v-model="fields" :options="options" multiple confirm>
      <div class="toggle-btn">+ 筛选 <i class="fmico fmico-paixu-jiangxu"></i></div>
    </fm-down-select>
  </div>
</template>

<script>
function getDefaultSearch (filters) {
  let search = {}
  filters.forEach(v => {
    search[v.field] = v.default !== undefined ? v.default : null
  })
  return search
}

export default {
  props: {
    filters: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      fields: this.filters.filter(v => v.show).map(v => v.field),
      search: getDefaultSearch(this.filters)
    }
  },
  watch: {
    filters: {
      deep: true,
      handler (filters) {
        this.fields = filters.filter(v => v.show).map(v => v.field)
        this.search = getDefaultSearch(this.filters)
      }
    },
    fields: {
      deep: true,
      handler (fields) {
        for (let filter of this.filters) {
          if (!fields.includes(filter.field)) {
            this.search[filter.field] = null
          }
        }
        this.onChange()
      }
    }
  },
  computed: {
    inputs () {
      return this.filters.filter(v => this.fields.includes(v.field))
    },
    options () {
      return this.filters.map(v => {
        return {
          label: v.label,
          value: v.field
        }
      })
    }
  },
  methods: {
    onChange () {
      let res = {}
      Object.keys(this.search).forEach(key => {
        if (this.search[key] !== null) {
          res[key] = this.search[key]
        }
      })
      this.$emit('change', res, this.search)
    }
  }
}
</script>
